// .storybook/YourTheme.js

import { create } from '@storybook/theming'

export default create({
    appBg: '#fff',
    appBorderColor: '#F1F3FD',
    appBorderRadius: 4,
    appContentBg: '#F7F8FB',
    barBg: '#F1F3FD',
    barSelectedColor: '#5261AC',
    barTextColor: '#99A2CD',
    base: 'light',
    brandImage: 'https://www.irishlife.ie/static/shardMobile.6c9b2a91.svg',
    brandTarget: '_self',
    brandTitle: 'Irish Life',
    brandUrl: 'https://irishlife.ie',
    colorPrimary: '#5261AC',
    colorSecondary: '#5261AC',
    fontBase: 'Assistant, sans-serif',
    inputBg: '#fff',
    inputBorderRadius: 4,
    inputTextColor: '#0C1E68',
    textColor: '#766f90',
    textMutedColor: '#99A2CD',
})
